import * as client_hooks from '../../../src/hooks.client.ts';


export { matchers } from './matchers.js';

export const nodes = [
	() => import('./nodes/0'),
	() => import('./nodes/1'),
	() => import('./nodes/2'),
	() => import('./nodes/3'),
	() => import('./nodes/4'),
	() => import('./nodes/5'),
	() => import('./nodes/6'),
	() => import('./nodes/7'),
	() => import('./nodes/8'),
	() => import('./nodes/9'),
	() => import('./nodes/10'),
	() => import('./nodes/11'),
	() => import('./nodes/12'),
	() => import('./nodes/13')
];

export const server_loads = [];

export const dictionary = {
		"/": [2],
		"/articles": [3],
		"/articles/example": [4],
		"/articles/exploit": [5],
		"/articles/not-random": [6],
		"/articles/strategies": [7],
		"/articles/strategies/buy-until-win": [8],
		"/articles/strategies/stop-after-win": [9],
		"/faq": [10],
		"/signup-thank-you": [11],
		"/state/[slug]": [12],
		"/state/[slug]/game/[id]": [13]
	};

export const hooks = {
	handleError: client_hooks.handleError || (({ error }) => { console.error(error) }),

	reroute: (() => {})
};

export { default as root } from '../root.svelte';